.user_card {
  width: 350px;
  margin-top: auto;
  margin-bottom: auto;
  background: #74cfbf;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  min-height: 50vh;
  margin-right: -280%;
  margin-top: 25vh;
}

.form_container {
  margin-top: 20px;
}

#form-title {
  color: #fff;
}

.login_btn {
  width: 100%;
  background: #33ccff !important;
  color: white !important;
}
.login_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.login_container {
  padding: 0 2rem;
}
.input-group-text {
  background: #f7ba5b !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.input_user,
.input_pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

#messages {
  background-color: grey;
  color: #fff;
  padding: 10px;
  margin-top: 10px;
}

.userList {
  flex: 4;
  height: 550px;
  margin-top: 50px;
}

.userListUser {
  display: flex;
  align-items: center;
}

.userListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.userListEdit {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #3bb077;
  color: white;
  cursor: pointer;
  margin-right: 20px;
}

.userListDelete {
  color: red;
  cursor: pointer;
}

.search {
  display: flex;
  justify-content: center;
}

.mholder {
  margin-left: auto;
}

.widgetLgButton.Cleared {
  background-color: #e5faf2;
  color: #3bb077;
}
.widgetLgButton.Pending {
  background-color: #fff0f1;
  color: #d95087;
}

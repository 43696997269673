.userList {
  width: 1000px;
  height: 550px;
  margin-top: 50px;
}

.userListUser {
  display: flex;
  align-items: center;
}

.userListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.userListEdit {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #3bb077;
  color: white;
  cursor: pointer;
  margin-right: 20px;
}

.userListDelete {
  color: red;
  cursor: pointer;
}

.search {
  display: flex;
  justify-content: center;
}

#new {
  margin-right: 10px;
  margin-bottom: 5px;
}

.widgetLgButton {
  padding: 5px 7px;
  border: none;
  height: 30px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
}

.widgetLgButton.Approved {
  background-color: #e5faf2;
  color: #3bb077;
}
.widgetLgButton.Inactive {
  background-color: #fff0f1;
  color: #d95087;
}

.mholder {
  margin-left: auto;
}
.sidebar {
  flex: 1;
  height: calc(150vh - 50px);
  background-color: #108b9a;
  position: sticky;
  top: 50px;
  margin-right: 10px;
}

.sidebarWrapper {
  padding: 20px;
  color: #555;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 13px;
  color: rgb(187, 186, 186);
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  color: white;
}

.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: #2e3665;
}

.sidebarIcon {
  margin-right: 5px;
  font-size: 20px !important;
}
.comp_logo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-left: 30px;
}

.newUser {
  flex: 4;
}

.newUserForm {
  display: block;
  flex-wrap: wrap;
}

.newUserItem {
  width: 400px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 20px;
}

.newUserItem > label {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(151, 150, 150);
}

.newUserItem > input {
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
}

.newUserGender > input {
  margin-top: 15px;
}

.newUserGender > label {
  margin: 10px;
  font-size: 18px;
  color: #555;
}

.newUserSelect {
  height: 40px;
  border-radius: 5px;
}

.newUserButton {
  width: 200px;
  border: none;
  background-color: darkblue;
  color: white;
  padding: 7px 10px;
  font-weight: 600;
  border-radius: 10px;
  margin-top: 30px;
  cursor: pointer;
}
.sidebar {
  flex: 1;
  height: calc(150vh - 50px);
  background-color: #108b9a;
  position: sticky;
  top: 50px;
  margin-right: 10px;
}

.sidebarWrapper {
  padding: 20px;
  color: #555;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 13px;
  color: rgb(187, 186, 186);
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  color: white;
}

.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: #2e3665;
}

.sidebarIcon {
  margin-right: 5px;
  font-size: 20px !important;
}
.comp_logo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-left: 30px;
}

